import { createRouter,createWebHashHistory } from "vue-router"


const routes= [
	{
		path: '/',
        component: () => import('../components/homePage/index.vue'),
	},
    
    {
        path: '/AboutUs',
        component: () => import('../components/AboutUs/AboutUs.vue'),
    },
    {
        path: '/PrivacyAgreement',
        component: () => import('../components/PrivacyAgreement/PrivacyAgreement.vue'),
    },
];

const router = createRouter({
	history: createWebHashHistory('/'),
	routes,
});
export default router;