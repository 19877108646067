<template>
  <!-- 上面的导航栏 -->
  <div class="header">
    <div class="center">
      <div class="logo">
        <img src="./assets/创速logo1.png" />
        <!-- <div class="corporateName">长沙创速信息技术有限公司</div> -->
      </div>
      <div class="right">
        <router-link to="/">
          <div class="home">
            <div :class="{ title1: true, selection: route.path === '/' }">
              首页
            </div>
            <div class="imgSelect" v-if="route.path === '/'"></div>
          </div>
        </router-link>
        <router-link to="/AboutUs">
          <div class="home">
            <div
              :class="{ title1: true, selection: route.path === '/AboutUs' }"
            >
              关于我们
            </div>
            <div class="imgSelect" v-if="route.path === '/AboutUs'"></div>
          </div>
        </router-link>

        <!-- <div class="line"></div> -->
      </div>
    </div>
  </div>
  <router-view> </router-view>
  <!--  copyRight -->
  <div class="copy">
    <div class="flex-c">Copyright © 长沙创速信息技术有限公司, All Rights Reserved.</div>
    <div class="beian flex-c m-l-20px">
      <img src="./assets/beian-logo.png" width="16"  alt="湘公网安备43010502001717号"/>
      <a  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010502001717" target="_blank" style="color: #4674eb;margin-right: 20px"> &nbsp;湘公网安备43010502001717号</a>
      <a  href="https://beian.miit.gov.cn" target="_blank" style="color: #4674eb">湘ICP备20000223号</a>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
const route = useRoute();
// const handleClick = () => {
//   window.open("https://beian.miit.gov.cn");
// };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  .copy {
    height: 30px;
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: center;
    .flex-c{
      display: flex;
      align-items: center;
    }
    .beian {
      cursor: pointer;
    }
    .m-l-20px{
      margin-left: 20px;
    }
  }
}
.header {
  height: 66px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 4px 0px rgba(0, 1, 1, 0.09);
  display: flex;
  justify-content: center;
  .center {
    width: 1200px;
    padding-right: 40px;
    padding-left: 80px;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    height: 47px;
    width: 400px;
    padding-top: 12px;
    display: flex;
    .corporateName {
      margin-top: 12px;
      width: 219px;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0a1222;
    }
  }
  .right {
    width: 340px;
    height: 44px;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    .home {
      width: 80px;
      height: 45px;
      margin-top: 3px;
      .title1 {
        width: 80px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #243d7c;
      }
      .selection {
        font-weight: bold;
        color: #1043dd;
      }
      .imgSelect {
        width: 80px;
        height: 30px;
        background-image: url("./assets/pic_选中.png");
        background-position: 0px -3px;
      }
    }
    .line {
      width: 1px;
      height: 24px;
      margin-top: 3px;
      background-color: rgba(36, 61, 124, 0.5);
    }
  }
}
a {
  text-decoration: none;
}
</style>
